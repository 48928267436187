const theme = {
  colors: {
    text: "#232129",
  },
  fonts: {
    body: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  lineHeights: {
    body: 1.35,
  },
  breakpoints: ["40em", "58em", "64em"],
  text: {
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
    },
    paragraph: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      color: "text",
    },
  },
  cards: {
    primary: {
      padding: 4,
      borderRadius: 6,
      boxShadow: "0 0 4px rgba(0, 0, 0, 0.125)",
    },
  },
  links: {
    primary: {
      color: "text",
      textDecoration: "none",
      borderBottomStyle: "dashed",
      py: 1,
      "&:hover": {
        borderBottomStyle: "solid",
        bg: "#FAE9ED",
      },
    },
  },
  styles: {
    p: {
      lineHeight: "body",
      fontFamily: "body",
    },
  },
};

export default theme;
